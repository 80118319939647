import React from "react";
import {Card, Table} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {properties} from "../properties";
import * as Auth from "../AuthService";
import Image from "react-bootstrap/Image";
import * as _ from "lodash";
import './QAManager.css';
import api from "../api";

class QARow extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            edit: false,
            final: ''
        }
    }

    componentDidMount() {
        this.setState({final: this.props.finValue ? this.props.finValue.value : ''})
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props){
            this.setState({final:  this.props.finValue ? this.props.finValue.value : '', edit: false})
        }
    }

    handleSelect(value, field){
        this.props.showAttr(field);
        !this.props.hasFinals && this.props.handleSelect(value, field)
    }

    handleEnter(e) {
        if (e.key === 'Enter') {
            this.props.changeValue(e.target.value)
        }
    }

    render() {

        return (
            <tr>
                <td className='column attr' onClick={() => this.props.showAttr()}>{this.props.attr.attributeName}</td>
                <td className={'column ' + (this.props.hasFinals ? '' : this.props.selectedField === 'annot' ? 'active-value' : '')}
                    onClick={event => this.handleSelect(this.props.annValue, 'annot') }>
                    {this.props.annValue ? this.props.annValue : '-'}
                </td>
                <td className={'column ' + (this.props.hasFinals ? '' : this.props.selectedField === 'extr' ? 'active-value' : '')}
                    onClick={event => this.handleSelect(this.props.extrValue, 'extr')}>
                    {this.props.extrValue ? this.props.extrValue : '-'}
                </td>
                <td className={'column ' + (this.props.hasFinals ? '' : this.props.selectedField === 'hint' ? 'active-value' : '')}
                    onClick={event => this.handleSelect(this.props.hintValue, 'hint')}>
                    {this.props.hintValue ? this.props.hintValue : '-'}
                </td>
                <td className='column'>{this.props.stanValue ? this.props.stanValue : '-'}</td>
                <td className='column' style={{backgroundColor: this.props.hasFinals ? "#dbfff8" : ''}} onDoubleClick={() => this.setState({edit: true})}>
                    {this.state.edit ?
                        <div>
                            <input onChange={(event) => this.setState({final: event.target.value})}
                                   onKeyDown={(e) => this.handleEnter(e)}
                                   value={this.state.final ? this.state.final : ''}/>
                                   <Image onClick={() => this.props.changeValue(this.state.final)} src='/images/check.png'/>
                                   <Image onClick={() => this.setState({edit:false, final: this.props.finValue ? this.props.finValue.value : '-'})} src='/images/close.png'/>
                        </div> :
                        this.props.finValue ? this.props.finValue.value : '-'}
                </td>
            </tr>
        );
    }

}

class QATable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAddAttrMode: false,
            attr: ''
        }
    }

    componentDidMount() {
        api.get(`/attributes/get`, Auth.createConfig())
            .then(json => this.setState({attributes: json.data}))
            .catch(error => console.log(error))
    }

    saveAttr(value) {
        let attr = {
            'attributeName': value ? value : this.state.attr,
            'customName': value ? value : this.state.attr
        }
        console.log(attr)
        api.post(`/attribute/add?class=${this.props.item.class_name}`, attr, Auth.createConfig())
            .then(json => {
                alert('Saved!')
                this.props.refresh(attr)
                this.setState({isAddAttrMode:false, attr: ''})
            })
            .catch(error => console.log(error))
    }

    onKey(e) {
        if (e.key === 'Enter') {
            this.saveAttr(e.target.value)
        }
    }

    handleAttrSelect(event, attr) {
        this.setState({attr: attr})
    }


    render() {

        let rows = this.props.attributes.map((v,i) => v.attributeName !== 'IGNORE LIST' ? <QARow key={i}
                                                                                                 attr={v}
                                                                                                 showAttr={(from) => this.props.showAttr(v.attributeName, from ? from : this.props.selected[v.attributeName].field)}
                                                                                                 hasFinals={this.props.hasFinals}
                                                                                                 changeValue={(value) => this.props.changeValue(value, v.attributeName)}
                                                                                                 handleSelect={(value, field) => this.props.handleSelect(v.attributeName, value, field)}
                                                                                                 annValue={this.props.annotated[v.attributeName]}
                                                                                                 finValue={this.props.final[v.attributeName]}
                                                                                                 stanValue={this.props.standard[v.attributeName]}
                                                                                                 selectedField={this.props.selected[v.attributeName] ? this.props.selected[v.attributeName].field : ''}
                                                                                                 extrValue={this.props.extracted[v.attributeName]}
                                                                                                 hintValue={this.props.hints[v.attributeName]} /> : null);
        return (
            <Table bordered hover className='Stats-qa'>
                <thead>
                <tr>
                    <th className='column'/>
                    <th className='column'>Annotation Based Value</th>
                    <th className='column'>Rule Based Value</th>
                    <th className='column'>AI based Value</th>
                    <th className='column'>Standardized Value</th>
                    <th className='column'>Final Value</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </Table>
        );
    }

}

class DescriptionCard extends React.Component {

    boldText(value, v) {
        return value.substr(0, v.from) +
            '<span title="' + v.attr + '" class="selected" style=background-color:'+ "#ffeadb" + '>' + value.substr(v.from, v.length) + '</span>' +
            value.substr(v.from + v.length)
    }
    checkSelection(annotation, sorted){
        for (let i = sorted.length; i > 0; i--) {
            if(i === sorted.length){
                if (annotation.from  >= sorted[i - 1].from + sorted[i - 1].length) {
                    return true;
                } else if (annotation.from + annotation.length <= sorted[i - 1].from ||(annotation.from >= sorted[i - 1].from + sorted[i - 1].length && annotation.from + annotation.length <= sorted[i].from)) {
                    return true
                }
            }
            else if(i > 1) {
                if (annotation.from + annotation.length <= sorted[i].from && annotation.from >= sorted[i - 1].from + sorted[i - 1].length) {
                    return true;
                }
            } else {
                if (annotation.from + annotation.length <= sorted[i - 1].from ||(annotation.from >= sorted[i - 1].from + sorted[i - 1].length && annotation.from + annotation.length <= sorted[i].from)) {
                    return true
                }
            }
        }
        return sorted.length === 0;

    }

    createDescription() {
        if (this.props.showVal.length === 0) {
            return this.props.item.description;
        } else {
            let sorted = _.sortBy(this.props.showVal, [function (o) {
                return o.from;
            }]);
            let value = this.props.item.description;
            let showed = [];
            for (let i = sorted.length; i > 0; i--) {
                if(this.checkSelection(sorted[i - 1], showed)) {
                    value = this.boldText(value, sorted[i - 1]);
                    showed.push(sorted[i - 1])
                }
            }
            return value;
        }
    }

    render() {

        // let descr = this.props.item.description;
        // if(this.props.showVal.length) {
        //     let val = this.props.showVal;
        //     descr = <span>
        //         {descr.substr(0, val.from)}
        //     <span className='selected' style={{backgroundColor: '#ffeadb'}}>
        //         {descr.substr(val.from, val.length)}
        //     </span>{
        //         descr.substr(val.from + val.length)}</span>
        // }

        return(
            <Card border="secondary">
                <Card.Header style={{userSelect: 'none'}}
                             onSelectCapture={() => false}>
                    Item ID: {this.props.item.item} {this.props.hasFinals ? <Image src='/images/star.png'/> :  ''} <span className='d-inline align-items-center' style={{color: 'green'}}>{this.props.hasFinals ? 'This record was checked! ' : ''}</span>
                    <Button className='float-right' onClick={() => this.props.getNext()}>Get Next</Button>
                </Card.Header>
                <Card.Body>
                    <Card.Text >
                        <div dangerouslySetInnerHTML={{__html: this.createDescription()}}/>
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }

}

class QAManager extends React.Component {

    getAttributes(){
        api.get(`/attributes?class=${this.props.item.class_name}`, Auth.createConfig())
            .then(json => this.setState({attributes: json.data.items}))
            .catch(error => console.log(error));
    }

    componentDidMount() {
        this.getAttributes();
        this.getAnnotated();
        this.getHints();
        this.getExtracted();
        this.firstSelection();
    }

    getAnnotated() {
        api.get(`/markup?item=${this.props.item.item}`, Auth.createConfig())
            .then(json => {
                let manualMarkups = json.data[0].manualMarkups;
                let annotated = {};
                for (let i = 0; i < manualMarkups.length; i++) {
                    let attr = manualMarkups[i].attr;
                    if (attr in annotated){
                        annotated[attr] = annotated[attr] + ',' + this.props.item.description.substr(manualMarkups[i].from, manualMarkups[i].length)
                    } else {
                        annotated[attr] = this.props.item.description.substr(manualMarkups[i].from, manualMarkups[i].length)
                    }
                }
                this.setState({annotated: annotated, fullMarkups: manualMarkups})
            })
            .catch(error => console.log(error))
    }

    getFinal(standarded) {
        api.get(`/final/markup?item=${this.props.item.item}`, Auth.createConfig())
            .then(json => {
                let finalMarkups = json.data[0].final_extr;
                let final = {};
                for (let i = 0; i < finalMarkups.length; i++) {
                    let attr = finalMarkups[i].attr;
                    final[attr] = {
                        changed: false,
                        value: finalMarkups[i].value}
                }
                if (Object.values(final).length === 0){
                    for (let key in standarded){
                        if (!final[key]  || !standarded[key].changed){
                            final[key] = {
                                value: standarded[key],
                                changed: false
                            }
                        }
                    }
                    this.setState({hasFinal: false})
                } else {
                    this.setState({hasFinal: true})
                }
                this.setState({final: final})
            })
            .catch(error => console.log(error))
    }

    getHints(){
        let result = {
            description: this.props.item.description
        };
        api.post(`/hints?class=${this.props.item.class_name}`, result, Auth.createConfig())
            .then(json => {
                let hints = {};
                for(let i =0; i<json.data.items.length;i++){
                    let attr = json.data.items[i].attr;
                    if (attr in hints){
                        hints[attr] = hints[attr] + ',' + json.data.items[i].value
                    } else {
                        hints[attr] = json.data.items[i].value
                    }
                }
                this.setState({hints: hints, fullHints: json.data.items})
            })
            .catch(error => console.log(error))
    }

    getExtracted(){
        api.get(`/item/extraction?item=${this.props.item.item}`, Auth.createConfig())
            .then(json => {
                let extracted = {};
                for(let i =0; i<json.data.items.length;i++){
                    let attr = json.data.items[i].attr;
                    if (attr in extracted){
                        extracted[attr] = extracted[attr] + ',' + json.data.items[i].value
                    } else {
                        extracted[attr] = json.data.items[i].value
                    }
                }
                this.setState({extracted: extracted, fullExtr:json.data.items })
            })
            .catch(error => console.log(error))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.item.class_name !== this.props.item.class_name){
            this.getAttributes();
        }
        if (prevProps.item !== this.props.item){
            this.getAnnotated();
            this.setState({hasFinals: false,showVal: {}});
            this.getHints();
            this.getExtracted();
            this.firstSelection();
        }
        if (prevState.attributes !== this.state.attributes || prevState.annotated !== this.state.annotated || prevState.hints!== this.state.hints || this.state.extracted !== prevState.extracted){
            this.setState({hasFinals: false,showVal: {}});
            this.firstSelection();
        }
    }

    constructor(props){
        super(props);
        this.state = {
            attributes: [],
            annotated: {},
            hints:{},
            hasFinal: false,
            final: {},
            showVal: {},
            selected: {},
            extracted: {},
            standard: {}
        }
    }

    firstSelection(){
        let selected = {};
        for (let attr = 0; attr < this.state.attributes.length; attr++){
            selected[ this.state.attributes[attr].attributeName] = {
                value: this.state.annotated[this.state.attributes[attr].attributeName] ?
                        this.state.annotated[this.state.attributes[attr].attributeName] :
                        this.state.extracted[this.state.attributes[attr].attributeName] ?
                            this.state.extracted[this.state.attributes[attr].attributeName] :
                            this.state.hints[this.state.attributes[attr].attributeName] ?
                                this.state.hints[this.state.attributes[attr].attributeName] : '',
                field: this.state.annotated[this.state.attributes[attr].attributeName] ?
                    'annot' :
                    this.state.extracted[this.state.attributes[attr].attributeName] ?
                        'extr' :
                        this.state.hints[this.state.attributes[attr].attributeName] ?
                            'hint' : ''
            }
        }
        this.setState({selected: selected});
        this.getStandard(selected)
    }

    refresh(attr) {
        let attributes = this.state.attributes;
        attributes.push(attr)
        this.setState({attributes: attributes})
    }

    getStandard(standard){
        api.post(`/standard`,standard, Auth.createConfig())
            .then(json => {
                let standarded = {};
                for(let attr in json.data.items){
                    if (attr in standarded){
                        standarded[attr] = standarded[attr] + ',' + json.data.items[attr].std
                    } else {
                        standarded[attr] = json.data.items[attr].std
                    }
                }
                this.setState({standard: standarded});
                this.getFinal(standarded)
            })
            .catch(error => console.log(error))
    }

    handleSubmit() {
        let markups = [];
        for (let attr in this.state.final){
            if(this.state.final[attr].value) {
                markups.push({
                    attr: attr,
                    value: this.state.final[attr].value
                })
            }
        }
        let result = {
            id: this.props.item.item,
            final: markups
        };
        api.post(`/save/final/markup`, result, Auth.createConfig())
            .then(json => {
                // alert('This record was saved');
                this.getFinal()
            })
            .catch(error => console.log(error))
    }

    handleSelection(attr, value, field){
        if(value) {
            let selected = this.state.selected;
            if (!this.state.final[attr].changed) {
                selected[attr] = {
                    value: value,
                    field: field
                };
                this.setState({selected: selected});
                this.getStandard(selected)
            }
        }
    }

    getByAttr(array, attr){
        let result = [];
        for (let i = 0; i < array.length; i++) {
            if(array[i].attr === attr){
                result.push(array[i]);
            }
        }
        return result;
    }

    showAttr(attr, field) {
        if (field === 'annot'){
            this.setState({showVal: this.getByAttr(this.state.fullMarkups, attr)})
        } else if (field === 'extr'){
            this.setState({showVal: this.getByAttr(this.state.fullExtr, attr)})
        } else if (field === 'hint'){
            this.setState({showVal: this.getByAttr(this.state.fullHints, attr)})
        }
    }

    changeValue(value, attr) {
        let final = this.state.final;
        let selected = this.state.selected;
        final[attr] = {
            changed:true,
            value: value
        };
        selected[attr] = value;
        this.setState({selected: selected});
        this.setState({final: final});
    }

    render() {
        return(
            <div>
                <DescriptionCard item={this.props.item}
                                 showVal={this.state.showVal}
                                 hasFinals={this.state.hasFinal}
                                 getNext={() => this.props.getNext()}
                />
                <QATable attributes={this.state.attributes}
                         handleSelect={(attr, value, field) => this.handleSelection(attr, value, field)}
                         selected={this.state.selected}
                         final={this.state.final}
                         item={this.props.item}
                         refresh={(attr) => this.refresh(attr)}
                         changeValue={(value, attr) => this.changeValue(value, attr)}
                         showAttr={(attr, field) => this.showAttr(attr, field)}
                         standard={this.state.standard}
                         annotated={this.state.annotated}
                         hasFinals={this.state.hasFinal}
                         extracted={this.state.extracted}
                         hints={this.state.hints}/>
                         <Button className='float-right' onClick={() => this.handleSubmit()}>Submit</Button>
            </div>
        );
    }

}

export default QAManager;
