import React from "react";
import './ExtractManager.css'
import Table from "react-bootstrap/Table";
import axios from "axios";
import {properties} from "../properties";
import * as Auth from "../AuthService";
// import {Button} from "react-bootstrap";
import Button from 'react-bootstrap-button-loader';
import Pagination from "react-js-pagination";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {StatisticsTable} from "../AdvancedStatistics/AdvancedStatistics";
import * as _ from "lodash";
import api from "../api";
const FileDownload = require('js-file-download');
require('promise.prototype.finally').shim();

class AttributeRow extends React.Component{

    render() {
        let v = this.props.attr;
        let descr = v.token?  <td>{v.description.substr(0, v.description.indexOf(v.token))} <span style={{backgroundColor:'#ffdcdb'}}> {v.description.substr(v.description.indexOf(v.token), v.token.length) }</span>{v.description.substr(v.description.indexOf(v.token) + v.token.length)}</td>
            : <td>{v.description}</td>;

        let token = '<span style="background-color: yellow">'+ v.token + '</span>';

        descr = _.replace(v.description, v.token, token);
        let hint = '';
        if( this.props.hint && this.props.hint.value.length > 0){
            for (let i = 0; i< this.props.hint.value.length; i ++ ) {
                for (let j = 0; j < this.props.hint.value[i].tokens.length; j ++) {
                    let freaq =  this.props.hint.value[i].tokens[j].freaq;
                    let proc = (freaq / this.props.total).toPrecision(2) * 100;
                    let title =  freaq + '(' + proc + '%)';
                    token = '<span class="rule" title=' + title + '>' + this.props.hint.value[i].tokens[j].token + '</span>';
                    let token_value = new RegExp( this.props.hint.value[i].tokens[j].token,'g');
                    descr = _.replace(descr, token_value, token);
                }
                hint = '<span style="background-color: #dbffdb">' + this.props.hint.value[i].hint + '</span>';
                let hint_value = new RegExp(this.props.hint.value[i].hint,'g');
                descr = _.replace(descr, hint_value, hint);
            }
        }

        return (
            <tr>
                <td>{this.props.index}</td>
                <td>{v.item}</td>
                <td>{v.source}</td>
                <td>
                    <span dangerouslySetInnerHTML={{__html: descr}}/>
                </td>
                <td>{v.token}</td>
                <td>{v.value}</td>
                <td><a href='#showrule' onClick={() => this.props.openRule(v.rule_id)}>{v.name ? v.name : 'Create new rule'}</a></td>
            </tr>
        );
    }

}

class AttributeTable extends React.Component {

    render() {
        const rows = this.props.values.map((v, i) =>
            <AttributeRow key={i+1}
                          total = {this.props.total}
                          hint={this.props.hints[i]}
                          index={i + 1 + (this.props.currentKey - 1) * properties.previewRowsPerPage}
                          openRule={(rule_id) => this.props.openRule(rule_id,v)}
                          attr={v} />);

        return (
            rows.length > 0 ?
                <div>
                    <Table bordered hover className='Stats'>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Item ID</th>
                            <th>Source</th>
                            <th>Description</th>
                            <th>Used Token</th>
                            <th>Value</th>
                            <th>Rule</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                    {this.props.size > properties.previewRowsPerPage &&
                    <div>
                        <Pagination itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={this.props.currentKey}
                                    itemsCountPerPage={properties.previewRowsPerPage}
                                    totalItemsCount={this.props.size}
                                    pageRangeDisplayed={25}
                                    onChange={(page) => this.props.handlePagination(page - 1)}
                        />
                    </div>
                    }
                </div> : null
        );
    }

}

class InfoAttr extends React.Component {

    render() {
        let rules = [];
        let affected = this.props.attr['affected'];
        for (let rule in this.props.attr) {
            if(rule !== 'affected'){
                let fr = (this.props.attr[rule] / this.props.total).valueOf().toPrecision(3) * 100;
                rules.push(<li style={{marginLeft: 10}} key={rule}>by {rule}: <span
                    onClick={() => this.props.handleFilter(rule)} style={{
                    color: 'blue',
                    textDecoration: 'underline'
                }}>{this.props.attr[rule]}</span> (Coverage {Math.round(fr)}%)</li>)
            }
        }

        let freak = (affected / this.props.total).valueOf().toPrecision(2) * 100;

        return (
            <div style={{color:"black", backgroundColor: this.props.total - affected < 0 ? 'yellow' : ''}}>
                <ul>
                    {this.props.name !== 'not_affected' && this.props.name}
                    <li>Unique items affected by existing rules:
                        <span style={{color:'blue', textDecoration:'underline'}}
                              onClick={() => this.props.handleFilter('affected')}>
                                {affected}
                            </span>
                        (Coverage {Math.round(freak)}%)
                    </li>
                    {rules}
                    <li>Unique items not affected by existing rules:
                        <span style={{color:'blue', textDecoration:'underline'}}
                              onClick={() => this.props.handleFilter('not_affected')}>
                                {this.props.total - affected}
                            </span>
                        (Coverage {100 - Math.round(freak)}%)
                    </li>
                </ul>
            </div>
        );
    }
}

class MappingInfoAttr extends React.Component {

    render() {
        let rules = [];
        let affected = this.props.attr['affected'];
        for (let rule in this.props.attr) {
            if (rule !== 'affected' && rule !== 'total') {
                let fr = (this.props.attr[rule] / this.props.attr.total).valueOf().toPrecision(3) * 100;
                rules.push(<li style={{marginLeft: 10}} key={this.props.name}>by {rule}: <span
                    onClick={() => this.props.handleFilter(rule)} style={{
                    color: 'blue',
                    textDecoration: 'underline'
                }}>{this.props.attr[rule]}</span> (Coverage {Math.round(fr)}%)</li>);
            }
        }

        let freak = (affected / this.props.attr.total).valueOf().toPrecision(2) * 100;

        return (
            <div style={{color:"black", backgroundColor: this.props.total - this.props.attr < 0 ? 'yellow' : ''}}>
                <ul>
                    {this.props.name !== 'not_affected' && this.props.name}
                    <li>Unique items affected by existing rules:
                        <span style={{color:'blue', textDecoration:'underline'}}
                              onClick={() => this.props.handleFilter('affected')}>
                                {affected}
                            </span>
                        (Coverage {Math.round(freak)}%)
                    </li>
                    {rules}
                    <li>Unique items not affected by existing rules:
                        <span style={{color:'blue', textDecoration:'underline'}}
                              onClick={() => this.props.handleFilter('not_affected')}>
                                {this.props.attr.total - affected}
                            </span>
                        (Coverage {100 - Math.round(freak)}%)
                    </li>
                </ul>
            </div>
        );
    }
}

class MappingInfo extends React.Component {

    state = {};

    render() {

        if(this.props.info) {
            let stats = [];
            let affected = this.props.info['curr'];
            for(let _class in this.props.info) {
                if (_class !== 'curr' && _class !== 'total') {
                    stats.push(<MappingInfoAttr attr={this.props.info[_class]} name={_class}
                                                 handleFilter={(rule) => this.props.handleFilter(_class, rule)}
                                                 total={this.props.info['total']}/>)
                        }
                    }

            let freak = (affected / this.props.info.total).valueOf().toPrecision(2) * 100;

            return (
                <div className='border' style={{color:"black", maxHeight: 200, overflowY: 'auto'}}>
                    <h3><b>Stats</b></h3>
                    <li>total items:
                        <span style={{color:'blue', textDecoration:'underline'}}
                              onClick={() => this.props.handleFilter('','all')}>
                                {this.props.info['total']}
                            </span>
                    </li>
                    {stats}
                </div>
            );
        } else {
            return null;
        }
    }

}

class Info extends React.Component {

    state = {};

    render() {

        if(this.props.info) {

            let stats = [];
            for(let _class in this.props.info) {
                if (_class !== 'curr' && _class !== 'total' && _class !== 'OFFSHORE' && _class !== ['ONSHORE']) {
                    stats.push(<p>{_class} [ {this.props.info[_class]['total']} ]</p>);
                    for (let attr in this.props.info[_class]) {
                        if(attr !== 'total' && attr !== 'not_affected') {
                            stats.push(<InfoAttr attr={this.props.info[_class][attr]} name={attr}
                                                 handleFilter={(rule) => this.props.handleFilter(_class, rule, attr)}
                                                 total={this.props.info[_class]['total']}/>)
                        }
                    }
                }
            }

            return (
                <div className='border' style={{color:"black", maxHeight: 200, overflowY: 'auto'}}>
                    <h3><b>Stats</b></h3>
                    <li>total items:
                        <span style={{color:'blue', textDecoration:'underline'}}
                              onClick={() => this.props.handleFilter('','all')}>
                                {this.props.info['total']}
                            </span> (<span> ONSHORE: {this.props.info['ONSHORE']}, OFFSHORE: {this.props.info['OFFSHORE']}</span>)
                    </li>
                    {stats}
                </div>
            );
        } else {
            return null;
        }
    }

}

class MappingRow extends React.Component{

    render() {
        let v = this.props.row;

        return (
            <tr>
                <td>{this.props.index}</td>
                <td>{v.item}</td>
                <td>
                    <span dangerouslySetInnerHTML={{__html: v.description}}/>
                </td>
                <td>{v.class}</td>
                <td>{v.value}</td>
                <td><a href='#showrule' onClick={() => this.props.openRule(v.rule_id)}>{v.name ? v.name : 'Create new rule'}</a></td>
            </tr>
        );
    }

}

class MappingTable extends React.Component {
    render() {
        const rows = this.props.values.map((v, i) =>
            <MappingRow key={i+1}
                          total = {this.props.total}
                          hint={this.props.hints[i]}
                          index={i + 1 + (this.props.currentKey - 1) * properties.previewRowsPerPage}
                          openRule={(rule_id) => this.props.openRule(rule_id,v)}
                          row={v} />);

        return (
            rows.length > 0 ?
                <div>
                    <Table bordered hover className='Stats'>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Item ID</th>
                            <th>Description</th>
                            <th>Old Class</th>
                            <th>Mapped Class</th>
                            <th>Rule</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                    {this.props.size > properties.previewRowsPerPage &&
                    <div>
                        <Pagination itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={this.props.currentKey}
                                    itemsCountPerPage={properties.previewRowsPerPage}
                                    totalItemsCount={this.props.size}
                                    pageRangeDisplayed={25}
                                    onChange={(page) => this.props.handlePagination(page - 1)}
                        />
                    </div>
                    }
                </div> : null
        );
    }
}

class ExtractManager extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            results: [],
            mapped: [],
            lastKey: 0,
            size: 0,
            currClass:'',
            currAttr:'',
            showHints: false,
            startSubmit: false,
            runName: '',
            hints: [],
            extractInProgress: false,
            mappingInProgress: false,
            applyingInProgress: false,
            filter:'affected',
            hintInProgress: false,
            downloadExtractInProgress: false,
            downloadMappingInProgress: false,
        }
    }

    componentDidMount() {
        this.setState({
            currClass : this.props.item ,
            currAttr : this.props.currentAttr.attrName,
        })
    }

    handleExport(){
        let run = prompt('Input run name: ', '');
        if (run) {
            this.setState({startSubmit:true});
            api.get(`/export/run?batch=${this.props.batch}&class=&attr=&run=${run}&filter=affected`, Auth.createConfig())
                .then(json => {
                    alert('Run was exported!');
                })
                .catch(error => alert(error.response.data))
                .finally(() => this.setState({startSubmit: false}))
        }
    }

    getHints(show = false) {
        if(!this.state.showHints || show) {
            this.setState({hintInProgress: true});
            let source = [];
            for (let i = 0; i < this.state.results.length; i++) {
                source.push(this.state.results[i].item)
            }
            api.get(`/hints?batch=${this.props.batch}&items=${source.join(',')}&class=${this.state.currClass}&attr=${this.state.currAttr}`, Auth.createConfig())
                .then(json => this.setState({hints: json.data.items, showHints: true, total: this.state.info[this.state.currClass]['total']}))
                .catch(error => console.log(error))
                .finally(() => this.setState({hintInProgress: false}));
        } else {
            this.setState({showHints: false, hints: []})
        }
    }

    extract(from,_class, filter = 'affected', attr=''){
        this.setState({extractInProgress: true});
        let currClass = filter === 'all' ? '' : _class ? _class : this.state.currClass ? this.state.currClass : '';
        let currAttr =  filter === 'all' ? '' : attr ? attr : this.state.currAttr ? this.state.currAttr : '';
        api.get(`/extraction-manager?batch=${this.props.batch}&class=${currClass}&attr=${currAttr}&from=${from * properties.previewRowsPerPage}&filter=${filter}`, Auth.createConfig())
            .then(json => this.setState({results: json.data.result,
                info: !this.state.info ? json.data.info : this.state.info,
                size: json.data.info['curr'],
                lastKey: from,
                mapped: [],
                mappedInfo: '',
                currAttr: currAttr,
                currClass: currClass,
                filter: filter}))
            .catch(error => alert(error.response.data))
            .finally(() => this.setState({extractInProgress:false}))
    }

    export(){
        this.setState({downloadExtractInProgress: true});
        let url = `/extraction/report?batch=${this.props.batch}&class=${this.state.currClass}&attr=${this.state.currAttr}&filter=${this.state.filter}`;

        const config = Auth.createConfig();
        config['responseType'] = 'blob';

        api.get(url, config)
            .then(response => {
                const header = response.headers['content-disposition'];
                const filename = /filename=(.*)/.exec(header)[1];
                FileDownload(response.data, filename, filename);
            })
            .catch(error => alert(error))
            .finally(() => this.setState({
                triedToSubmit: false,
                downloadExtractInProgress: false
            }));
    }

    export_mapping(){
        this.setState({downloadMappingInProgress: true});
        let url = `/mapping/report?batch=${this.props.batch}&class=${this.state.currClass}&filter=${this.state.filter}`;

        const config = Auth.createConfig();
        config['responseType'] = 'blob';

        api.get(url, config)
            .then(response => {
                const header = response.headers['content-disposition'];
                const filename = /filename=(.*)/.exec(header)[1];
                FileDownload(response.data, filename, filename);
            })
            .catch(error => alert(error))
            .finally(() => this.setState({
                triedToSubmit: false,
                downloadMappingInProgress: false
            }));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.batch !== this.props.batch){
            this.setState({results: [],hints:[], mapped: [], size: 0, lastKey: 0, currAttr: this.props.currentAttr.attrName, info: '', mappedInfo: ''})
        }
        if(prevProps.currentAttr !== this.props.currentAttr) {
            this.setState({results: [],hints:[],mapped: [], size: 0, lastKey: 0, currAttr: this.props.currentAttr.attrName, info: '',  mappedInfo: ''})
        }
        if (prevProps.item !== this.props.item) {
            this.setState({
                info: '',
                hints:[],
                mappedInfo: '',
                currClass : this.props.item ,
                currAttr : this.props.currentAttr.attrName,
            })
        }
        if(this.state.results !== prevState.results && this.state.filter === 'not_affected'){
            this.getHints(this.state.showHints)
        }
        if(prevProps.shouldUpdate !== this.props.shouldUpdate) {
            this.setState({results: [],hints:[], mapped: [],  mappedInfo: '', size: 0, lastKey: 0, currAttr: this.props.currentAttr.attrName, info: ''})
        }
    }

    mapped(from,_class, filter = 'affected', attr=''){
        this.setState({mappingInProgress: true});
        let currClass = filter === 'all' ? '' : _class ? _class : this.state.currClass ? this.state.currClass : '';
        api.get(`/mapping?batch=${this.props.batch}&class=${currClass}&from=${from * properties.previewRowsPerPage}&filter=${filter}`, Auth.createConfig())
            .then(json =>
                this.setState({mapped: json.data.result,
                mappedInfo: !this.state.mappedInfo ? json.data.info : this.state.mappedInfo,
                size: json.data.info['curr'],
                lastKey: from,
                    info:'',
                results: [],
                currClass: currClass,
                filter: filter})
            )
            .catch(error => console.log(error))
            .finally(() => this.setState({mappingInProgress:false}))
    }

    applyMapping(from,_class){
        this.setState({applyingInProgress: true});
        let currClass = _class ? _class : this.state.currClass ? this.state.currClass : '';
        api.get(`/apply/mapping?batch=${this.props.batch}&class=${currClass}`, Auth.createConfig())
            .then(json => {
                    alert('Classes were mapped');
                    this.props.handleUpdate();
                }
            )
            .catch(error => console.log(error))
            .finally(() => this.setState({applyingInProgress:false}))
    }

    render() {
        let filt = (this.state.filter !== 'all' && this.state.filter !== 'affected' && this.state.filter !== 'not_affected') ? 'rows covered with ' + this.state.filter : this.state.filter.replace('_', ' ') + ' rows';
        return(
            <div>
                <div className="Extract-Manager">
                    <div>
                        <p>Attribute
                            <b> {this.state.currAttr} </b>
                            from
                            <b> {this.state.currClass} </b>
                            (batch <b>{this.props.batch}</b>) extraction results:
                            <Button onClick={() => this.extract(0)}
                                    style={{marginLeft: 5}}
                                    loading={this.state.extractInProgress}
                                    className='float-right'>Extract</Button>
                            <Button onClick={() => this.mapped(0)}
                                    loading={this.state.mappingInProgress}
                                    className='float-right'>Map</Button>
                            <Button onClick={() => this.applyMapping()}
                                    loading={this.state.applyingInProgress}
                                    className='float-right'>Apply Mapping</Button>
                            <Button className='single-btn-export'
                                    loading={this.state.downloadExtractInProgress}
                                    onClick={() => this.export()}>
                                Export extraction results
                            </Button>
                            <Button className='single-btn-export'
                                    style={{marginLeft: 5}}
                                    loading={this.state.downloadMappingInProgress}
                                    onClick={() => this.export_mapping()}>
                                Export mapping results
                            </Button>
                            {/*<Button className='single-btn-export' */}
                            {/*        style={{marginLeft: 5}} */}
                            {/*        loading={this.state.startSubmit} */}
                            {/*        onClick={() => this.handleExport()}>*/}
                            {/*    Save as run*/}
                            {/*</Button>*/}
                        </p>
                    </div>
                    <div style={{display:"inline"}}>
                        <Info token={this.props.currentAttr.attrName}
                              info={this.state.info}
                              handleFilter={(_class, filter, attr) =>  this.extract(0, _class, filter, attr)}/>
                    </div>
                    <div style={{display:"inline"}}>
                        <MappingInfo info={this.state.mappedInfo}
                                     handleFilter={(_class, filter) =>  this.mapped(0, _class, filter)}/>
                    </div>
                    {this.state.filter && <p>
                        Extraction results for <b>{filt}</b>
                       { (this.state.filter === 'not_affected' && this.state.results.length > 0) && <span><Button className='single-btn-export float-right'
                                                                                                             loading={this.state.hintInProgress}
                                onClick={() => this.getHints()}>
                            Get Hints
                        </Button>
                           <span className='float-right' style={{marginRight: 5}}>Show hints: <input type="checkbox" disabled value={this.state.showHints}/></span></span>
                       }
                    </p>}
                    <AttributeTable
                        total = {this.state.total}
                        hints = {this.state.hints}
                        handlePagination={(key) => this.extract(key,this.state.currClass, this.state.filter)}
                        currentKey={this.state.lastKey + 1}
                        size={this.state.size}
                        attr={this.props.currentAttr.attrName}
                        values={this.state.results}
                        openRule={(rule_id) => this.props.openRule(rule_id, this.state.currAttr)}/>
                    <MappingTable
                        total = {this.state.total}
                        hints = {this.state.hints}
                        handlePagination={(key) => this.mapped(key,this.state.currClass, this.state.filter)}
                        currentKey={this.state.lastKey + 1}
                        size={this.state.size}
                        attr={this.props.currentAttr.attrName}
                        values={this.state.mapped}
                        openRule={(rule_id) => this.props.openRule(rule_id, this.state.currAttr)}/>

                </div>
            </div>
        );
    }

}

class RootExtract extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            key: 'extract'
        }
    }

    handleSelection(k) {
        this.setState({key: k});
    }

    render() {

        if(this.props.item) {

            return (
                <Tabs id="controlled-tab-example" activeKey={this.state.key}
                      onSelect={k => this.handleSelection(k)}>
                    <Tab eventKey="extract" title="Extraction Results">
                        <ExtractManager batch={this.props.batch}
                                        item={this.props.item}
                                        handleUpdate={() => this.props.handleUpdate()}
                                        shouldUpdate={this.props.shouldUpdate}
                                        currentAttr={this.props.currentAttr}
                                        openRule = {(rule_id, attr) => this.props.openRule(rule_id, attr)}/>
                    </Tab>
                    <Tab eventKey="stats" title="Advanced Statistics">
                        <StatisticsTable
                            batch={this.props.batch}
                            // handleSave={() => this.setState({shouldUpdate: !this.state.shouldUpdate})}
                            // shouldUpdate={this.state.shouldUpdate}
                            openRule = {(rule_id) => this.props.openRule(rule_id)}
                            // createRule={(token, isGarb) => this.createRule(token, isGarb)}
                            class={this.props.item}
                            currentAttr={this.props.currentAttr}
                        />
                    </Tab>
                </Tabs>);
        } else {
            return(
                <ExtractManager batch={this.props.batch}
                                item={this.props.item}
                                shouldUpdate={this.props.shouldUpdate}
                                currentAttr={this.props.currentAttr}
                                openRule = {(rule_id, attr) => this.props.openRule(rule_id, attr)}/>
            );
        }
    }

}

export default RootExtract
