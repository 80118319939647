import React, {Component, useEffect, useState} from "react";
import {Descriptions, Modal, Popover, Table, Tag} from "antd";
import {properties} from "../properties";
import api from "../api";
import {Switcher} from "./Register";
import {EditableCell, EditableRow} from "./EditableCell";
import {CloseOutlined} from "@ant-design/icons";


export function ModalWindow ({title, isModalVisible, onCancel, onOk, children, modalProps}) {

    const headStyle = {
        borderColor: properties.kbrColors.pantone301Blue,
        color: properties.kbrColors.pantone301Blue
    }

    const bodyStyle = {
        height: '60vh',
        maxHeight: '60vh',
        overflowY: 'scroll'
    }

    return <Modal  width={'75vw'}
                   height={'60vh'}
                   title={<div style={headStyle}>{title}</div>}
                   visible={isModalVisible}
                   onCancel={onCancel}
                   onOk={onOk}
                   bodyStyle={{bodyStyle, ...modalProps}}
                   {...modalProps}
    >
        {children}
    </Modal>
}

export function SourceTag({source, closable=false, onClose}) {
    const tagStyles = {
        'P&ID': "red",
        'Datasheet': "orange",
        'Equipment list': "green",
        'Line List': "purple",
        'Supplier Datasheet': "blue",
        'data-mapper': "pink",
        'Lists': 'purple',
        'P&ID Master': 'red',
        'multi-value': '#1b998b'
    }

    if (source === 'multi-value') {
        return <Tag closable={closable} closeIcon={<CloseOutlined style={{verticalAlign: 'middle', color: tagStyles[source]}} />} onClose={onClose} color={tagStyles[source]}>{source}</Tag>

    }
    return <Tag closable={closable} closeIcon={<CloseOutlined style={{verticalAlign: 'middle', color: tagStyles[source]}} />} onClose={onClose} color={tagStyles[source]}>{source}</Tag>
}

function MatrixTable({item, mode, dataSource, loading, onLock}) {

    const [extraction, setExtraction] = useState([]);
    const [viewMode, setViewMode] = useState('descriptions')
    const [isLocked, setIsLocked] = useState(item.isLocked)
    const [showAllAttrs, setShowAllAttrs] = useState( false);
    const [options, setOptions] = useState([ {label: 'Descriptions View', value: 'descriptions'},
        {label: 'Table View', value: 'table'}])

    const getExtracted = () => {
        api.get(`/register/${mode}/get/extraction?material=${item.item}`)
            .then(json => setExtraction(preprocessAttributes(json.data['attributes'])))
    }

    const preprocessAttributes = attrs => {
        const result = [];
        for (let x of attrs) {
            let temp = {key: x['attr']}
            for (let key of x['extracted']) {
                if (key['value']) {
                    temp['value'] = key['value']
                    temp['source'] = key['source']
                    break;
                }
            }
            temp['full'] = x['extracted']
            result.push(temp)
        }
        return result;
    }

    useEffect(() => {
        if (!loading) {
            getExtracted()
            setViewMode('descriptions')
        }
    }, [loading])

    const getValue = attr => {
        const result = extraction.filter(a => a.key === attr)
        if (result.length > 0) {
            if (typeof result[0].value === "object") {
                return <div>{result[0].value.map(v=> <Tag style={{color: "#1b998b", backgroundColor: "#1b998b33", borderColor: '#1b998b'}}>{v}</Tag>)} <SourceTag source={result[0].source}/></div>
            } else{
                return <div>{result[0].value} <SourceTag source={result[0].source}/></div>
            }
        } else {
            return <div />
        }
    }

    const getPopoverValue = attr => {
        const result = extraction.filter(a => a.key === attr)
        if (result.length > 0) {
            const _attr = result[0]
            const content = []
            for (let source of _attr['full']) {
                let value = source.value;
                if (typeof value !== 'string') {
                    value = value.join(', ')
                }
                content.push(<div style={{margin: 10}}>{value || '(blank)'} <SourceTag source={source.source} /></div>)
            }
            return content
        } else {
            return <div>No value was extracted</div>
        }
    }

    const getAttrFilter = () => {
        return dataSource.map(attr => {
            return {
                'text': attr['name'],
                'value': attr['name']
            }
        })
    }

    const defaultColumns = [
        {
            title: 'Attribute',
            dataIndex: 'name',
            filterSearch: true,
            filters: getAttrFilter(),
            width: '20%',
            onFilter: (value, record) => record.name.includes(value),
            render: (name) => <div style={{float: "left"}}>{name}</div>
        },
        {
            title: 'Value',
            dataIndex: 'value',
            editable: true,
            render: (name, attr) => {
                const cellValue =  <Popover placement={'right'} content={getPopoverValue(attr.name)}><div style={{float: "left"}}>{getValue(attr.name)}</div></Popover>

                return isLocked ? cellValue :
                    <EditableCell style={{float: "left"}} handleSave={handleSave} record={{value: getValue(attr.name)}}>{cellValue}</EditableCell>
            }
        }
    ]

    const handleSave = (row) => {
        const newValue = {...row, 'source': 'user'}

        api.post(`/register/${mode}/save/extraction?material=${item.item}`, newValue)
            .then(() => getExtracted())
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        if(isLocked) {
            return col;
        }
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
                data: extraction,
            }),
        };
    });

    useEffect(() => {
        setIsLocked(item.isLocked)
    }, [item])

    const setLock = value => {
        api.post(`/register/${mode}/lock`, {set: value, _id: item._id})
            .then(() => {
                setIsLocked(value)
                value && onLock()
            })

    }

    const lockButton = <div onClick={() => setLock(!isLocked)} className='beautiful-link' style={{cursor: 'pointer', float: 'right'}} >{isLocked ? 'Unlock item' : 'Lock item'}</div>

    const showAllOptions = [
        {label: 'Hide', value: false},
        {label: 'Show', value: true}
    ]

    const getNeededAttrs = () => {
        if (showAllAttrs) {
            return dataSource
        } else {
            const notEmpty = []
            for (let attr of dataSource) {
                const attr_value = extraction.filter(a => a.key === attr['name'])
                if (attr_value.length > 0 && 'value' in attr_value[0]) {
                    notEmpty.push(attr)
                }
            }
            return notEmpty
        }
    }

    return (
        <div>
            <div style={{marginLeft: 10}}>Mode: <Switcher value={viewMode} setValue={setViewMode} options={options} />{lockButton}</div>
            <div style={{margin: 10}}>Attributes with empty values: <Switcher value={showAllAttrs} setValue={setShowAllAttrs} options={showAllOptions} /></div>
            <div style={{margin: 10}}>
            {viewMode === 'descriptions' && <Descriptions size={"small"} column={2} bordered>
                {getNeededAttrs().map((attr, i) => {
                    if (attr['name']) {
                        return <Descriptions.Item key={i} label={attr['name']}><Popover placement={'right'}
                                                                                        content={getPopoverValue(attr['name'])}>{getValue(attr['name'])}</Popover></Descriptions.Item>
                    }
                })}
            </Descriptions>}
            {viewMode === 'table' && <Table pagination={false} rowClassName={() => 'editable-row'} components={components} columns={columns} size={'small'} dataSource={getNeededAttrs()}/>}
            </div>
        </div>
    )
}



export class AttributeViewer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            loading: false,
            dataSource: []
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.item !== this.props.item) {
            this.getAttributes()
        }
    }

    getAttributes = () => {
        this.setState({loading: true})
        api.get(
            `/class-library/classes/name/${this.props.item.class}/attributes?type=${this.props.mode}`,
            api._withHeaders()
        ).then(
            response => {
                this.setState({dataSource: response.data.map(el => ({key: el.id, ...el}))})
            }
        ). finally(() => this.setState({loading: false}));
        this.getOptions()
    }

    getOptions = () => {
        api.get(`/source-matrix/options`)
            .then(json => {
                this.setState({options: json.data})
            })
    }

    render() {
        return <ModalWindow title={`${this.props.item.item} Info`}
                            isModalVisible={this.props.isModalVisible}
                            modalProps={{maxHeight: '60vh', overflowY: "auto"}}
                            onOk={() => this.props.close()}
                            scrollVisible={true}
                            onCancel={() => this.props.close()}>
            <div>
                <MatrixTable onLock={() => this.props.close()} item={this.props.item} mode={this.props.mode} dataSource={this.state.dataSource} setDataSource={value => this.setState({dataSource: value})} loading={this.state.loading} options={this.state.options}/>
            </div>

        </ModalWindow>
    }

}
