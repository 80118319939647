import React from 'react';
import {Layout, Menu} from 'antd';
import NavMenu from "../NavbarComponent/Menu";
import {properties, SHOW_DM_IN_MENU} from "../properties";

export const openLink = (href) => {
  window.open(href, '_self')
}

export class MenuLayout extends React.Component {

  render() {



    return (
      <Layout  style={{minHeight:"100vh", maxHeight: '100vh'}}>
        <NavMenu />
        <Layout>
        <Layout>
          <Layout.Sider width={'20%'} theme='light'>
            <Menu theme="light" mode="inline" style={{marginTop: 65}}>
                <Menu.Item key="Class Library" onClick={() => openLink(properties.clPublicLink)}>
                    Class Library
                </Menu.Item>
                {SHOW_DM_IN_MENU && <Menu.Item key="Data Mapper" onClick={() => openLink('/dm')}>
                    Data Mapper
                </Menu.Item>}
                <Menu.Item key="Maturity Source" onClick={() => openLink('/maturity-source')}>
                    Source Matrix
                </Menu.Item>
                <Menu.Item key="Register" onClick={() => openLink('/register')}>
                    Register
                </Menu.Item>
              <Menu.Item key="Admin" onClick={() => openLink('/dm/admin')}>
                  Settings
              </Menu.Item>
            </Menu>
          </Layout.Sider>

          <Layout.Content style={{width: '75%'}}>
              {this.props.children}
          </Layout.Content>
        </Layout>
        </Layout>
      </Layout>
    ) 
  }
}
