import React from "react";
import axios from "axios";
import {properties} from "../properties";
import * as Auth from "../AuthService";
import {ButtonGroup, Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import * as _ from 'lodash'
import './DataAnnotator.css'
import api from "../api";


class Results extends React.Component {

    render() {

        let result = this.props.annotated.map((v, i) =>
            <ListGroup.Item style={{paddingTop: '0.15rem', paddingBottom: '0.15rem'}} key={i}>
                [ {v.attr} ] - {v.value}
                <img alt='delete' className='float-right' src='/images/close-black.png'onClick={() => this.props.onDelete(v)}/>
                {v.match && v.match.status === 'full' && <img alt='full match' className='float-right' title={v.match.value} src='/images/check.png'/>}
                {v.match && v.match.status === 'part' && <img alt='part match' className='float-right' title={v.match.value} src='/images/part.png'/>}
                {v.match && v.match.status === 'none' && <img alt='none match' className='float-right' title={v.match.value} src='/images/none.png'/>}
            </ListGroup.Item>);

        return (
            <div style={{marginTop: 10, userSelect: 'none'}} onSelectCapture={() => false}>
                <Card border="secondary">
                    <Card.Header>You annotated:
                        <Button type='submit' variant="secondary" className='float-right' onClick={() => this.props.onSubmit()}>Submit Annotation</Button>
                        <Button style={{marginRight: 10}} type='reset' variant="secondary" className='float-right'
                                onClick={() => this.props.onReset()}>Reset annotation</Button>
                    </Card.Header>
                    <Card.Body>
                        <ListGroup variant="flush">
                            {result}
                        </ListGroup>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

class AttributeMap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
    }

    boldText(value, v) {
        return value.substr(0, v.from) +
            '<span title="' + v.attr + '" class="selected" style=background-color:' + v.color + '>' + value.substr(v.from, v.length) +
            '<img style=background-color:' + v.color + ' class="delete-attr" alt=Delete src="/images/close-white.png"/></span>' +
            value.substr(v.from + v.length)
    }

    createDescription() {
        if (this.props.annotated.length === 0) {
            return this.props.fullDescr;
        } else {
            let sorted = _.sortBy(this.props.annotated, [function (o) {
                return o.from;
            }]);
            let value = this.props.fullDescr;
            for (let i = sorted.length; i > 0; i--) {
                value = this.boldText(value, sorted[i - 1])
            }
            return value;
        }
    }

    clickHandler(e) {
        let el = e.target;
        if (el && el.tagName === "IMG") {
            el = el.parentNode;
            this.props.onDelete(_.filter(this.props.annotated, {'value': el.textContent})[0])
        }
    }

    render() {

        return (
            <div style={{marginBottom: 10, userSelect: 'none'}} onSelectCapture={() => false}>
                <Card border="secondary">
                    <Card.Header>Attributes Map:</Card.Header>
                    <Card.Body>
                        <div onClick={(e) => this.clickHandler(e)}
                             dangerouslySetInnerHTML={{__html: this.createDescription()}}/>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

class DataAnnotator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            attributes: [],
            annotated: [],
            serviceAttributes: []
        }
    }

    getColor(attr){
        if(_.filter(this.state.serviceAttributes, {'attributeName': attr}).length > 0){
            return properties.colors[this.state.serviceAttributes.indexOf(_.filter(this.state.serviceAttributes, {'attributeName': attr})[0])];
        }
        return properties.colors[this.state.attributes.indexOf(_.filter(this.state.attributes, {'attributeName': attr})[0]) + this.state.serviceAttributes.length];
    }

    getAnnotated() {
        api.get(`/markup?item=${this.props.currentItem.item}`, Auth.createConfig())
            .then(json => {
                let annotated = json.data[0].manualMarkups;
                for (let i = 0; i < annotated.length; i++) {
                    annotated[i].color = this.getColor(annotated[i].attr);
                    annotated[i].value = this.props.currentItem.description.substr(annotated[i].from, annotated[i].length)
                }
                this.setState({annotated: annotated.length > 0 ? annotated : []})
            })
            .catch(error => console.log(error))
    }

    getAttrs(){
        api.get(`/attributes?class=${this.props.currentItem.class_name}`, Auth.createConfig())
            .then(json => this.setState({attributes: json.data.items}))
            .catch(error => console.log(error));
        api.get(`/service-attributes`, Auth.createConfig())
            .then(json => this.setState({serviceAttributes: json.data.attrs}))
            .catch(error => console.log(error));
    }

    componentDidMount() {
        this.getAttrs();
        this.getAnnotated();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentItem !== this.props.currentItem) {
            if(prevProps.currentItem.class_name !== this.props.currentItem.class_name){
               this.getAttrs();
            }
            this.getAnnotated();
            this.setState({
                annotated: []
            })
        }
    }

    checkSelection(annotation){
        let sorted = _.sortBy(this.state.annotated, [function (o) {
            return o.from;
        }]);
        for (let i = sorted.length; i > 0; i--) {
            if(i === sorted.length){
                if (annotation.from  >= sorted[i - 1].from + sorted[i - 1].length) {
                    return true;
                } else if (annotation.from + annotation.length <= sorted[i - 1].from) {
                    return true
                }
            }
            else if(i > 1) {
                if (annotation.from + annotation.length <= sorted[i].from && annotation.from >= sorted[i - 1].from + sorted[i - 1].length) {
                    return true;
                }
            } else {
                if (annotation.from + annotation.length <= sorted[i - 1].from ||(annotation.from >= sorted[i - 1].from + sorted[i - 1].length && annotation.from + annotation.length <= sorted[i].from)) {
                    return true
                }
            }
        }
        return false;
    }

    handleSelection(button, color) {
        let s = window.getSelection().toString();
        if (s.length > 0) {
            let from = window.getSelection().anchorOffset;
            let value = this.props.currentItem.description;
            let old = this.state.annotated;
            let annotation = {
                attr: button,
                value: value.substr(from, s.length),
                length: s.length,
                from: from,
                color: color
            };
            if(this.checkSelection(annotation)) {
                old.push(annotation);
                this.setState({annotated: old})
            } else if(old.length === 0){
                old.push(annotation);
                this.setState({annotated: old})
            }
        }

    }

    onSubmit(){
        let markups = [];
        for (let i  = 0; i < this.state.annotated.length; i++){
            markups.push({
                attr: this.state.annotated[i].attr,
                length: this.state.annotated[i].length,
                from: this.state.annotated[i].from
            })
        }
        let result = {
            id: this.props.currentItem.item,
            annotated: markups
        };
        api.post(`/save/markup`, result, Auth.createConfig())
            .then(json => this.props.onSubmit())
            .catch(error => console.log(error))

    }

    getHints(){
        let result = {
            description: this.props.currentItem.description
        };
        api.post(`/hints?class=${this.props.currentItem.class_name}`, result, Auth.createConfig())
            .then(json => {
                let old = this.state.annotated;
                for(let i =0; i<json.data.items.length;i++){
                    let annotation = {
                        attr: json.data.items[i].attr,
                        value: json.data.items[i].value,
                        length: json.data.items[i].value.length,
                        from: this.props.currentItem.description.indexOf(json.data.items[i].value),
                        color: this.getColor(json.data.items[i].attr)
                    };
                    if(this.checkSelection(annotation)) {
                        old.push(annotation);
                    } else if(old.length === 0){
                        old.push(annotation);
                    }
                }
                this.setState({annotated: old})
            })
            .catch(error => console.log(error))
    }

    verify() {
        let result = {
            description: this.props.currentItem.description,
            markups: this.state.annotated
        };
        api.post(`/verify?class=${this.props.currentItem.class_name}`, result, Auth.createConfig())
            .then(json => {
                let annotated = this.state.annotated;
                let verified = json.data.items;
                for (let i = 0; i < annotated.length; i++) {
                    for (let j = 0; j < verified.length; j++){
                        if(annotated[i].attr === verified[j].attr && annotated[i].value === verified[j].old_value) {
                            if (!annotated[i].match || annotated[i].match.status !== 'full') {
                                annotated[i].match = {status: verified[j].match, value: verified[j].value};
                            }
                        }
                    }
                }
                this.setState({annotated: annotated})
            })
            .catch(error => console.log(error))
    }

    onDelete(i) {
        let old = this.state.annotated;
        old.splice(old.indexOf(i), 1);
        this.setState({annotated: old})
    }

    groupButtons(array, offset = 0, name = 'attributes') {
        return array.map((v, i) => {
            let class_name = 'single-btn';
            let filterObj = name==='attributes' ? {'attr': this.state[name][i + offset - this.state.serviceAttributes.length].attributeName} : {'attr': this.state[name][i + offset].attributeName};
            if (_.filter(this.state.annotated, filterObj).length > 0) {
                class_name = 'selected-btn';
            }
            return <Button className={class_name} style={{backgroundColor: properties.colors[i + offset], fontSize:13}}
                           key={i + offset}
                           onClick={() => this.handleSelection(v.attributeName, properties.colors[i + offset])}>
               { v.customName !== v.attributeName && <div>
                    <i style={{textDecoration: 'underline', fontSize: 10, float:'left', marginBottom: 0, marginRight: 0, marginLeft: 3}}>
                        {v.customName}</i>
                </div>}
                        {v.attributeName}
            </Button>
        })
    }

    render() {

        if (this.props.currentItem.description) {

            if (this.state.attributes) {
                let full = this.state.attributes;

                let service = this.groupButtons(this.state.serviceAttributes, 0, 'serviceAttributes');
                let group1 = this.groupButtons(full.slice(0, 14), this.state.serviceAttributes.length);

                let group2 = full.slice(14) ? this.groupButtons(full.slice(14),  this.state.serviceAttributes.length + 14) : [];

                return (
                    <div className='row'>
                        <ButtonGroup style={{justifyContent: 'start'}} vertical
                                     className={'col-md-2'}>
                            {group1}
                        </ButtonGroup>
                        {group2.length > 0 &&
                        <ButtonGroup style={{justifyContent: 'start'}} vertical className='col-md-2'>
                            {group2}
                        </ButtonGroup>
                        }
                        <div style={{marginTop: 10}} className={group2.length > 0 ? 'col-md-6' : 'col-md-8' }>
                            <AttributeMap annotated={this.state.annotated}
                                          fullDescr={this.props.currentItem.description}
                                          onDelete={(i) => this.onDelete(i)}/>
                            <Card border="secondary">
                                <Card.Header style={{userSelect: 'none'}}
                                             onSelectCapture={() => false}>
                                    Item ID: {this.props.currentItem.item}
                                    {this.state.annotated.length === 0 && <Button className='single-btn-export float-right'
                                                                     onClick={() => this.getHints()}>
                                        Get Hints
                                    </Button>}
                                    {this.state.annotated.length > 0 && <Button className='single-btn-export float-right'
                                                                                  onClick={() => this.verify()}>
                                        Verify annotations
                                    </Button>}
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {this.props.currentItem.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Results annotated={this.state.annotated} onDelete={(i) => this.onDelete(i)}
                                     onReset={() => this.setState({annotated: []})}
                                     onSubmit={() => this.onSubmit()}
                            />
                        </div>
                        <ButtonGroup style={{justifyContent: 'start'}} vertical className='col-md-2'>
                            {service}
                        </ButtonGroup>
                    </div>
                );
            } else {
                return (
                    <div>
                        [{this.props.currentItem.item}] {this.props.currentItem.description}
                    </div>
                );
            }
        } else {
            return <h5 className='m-auto'>You need to select item first!</h5>
        }
    }

}

export default DataAnnotator;
