import React from "react";
import * as Auth from "../AuthService";
import Image from "react-bootstrap/Image";
import './NavigationBar.css'
import api from "../api";
import {AutoComplete} from "antd";

class NavigationBarRule extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        }
    }

    render() {
        return (
            <div className="Navigation-attr">
                <a style={{color:  'blue', marginLeft:24}}
                   onClick={event => { event.preventDefault();  this.props.onRuleSelect(this.props.rule._id)}}
                   href={`/extract-manager`}><i> {this.props.rule.name}</i></a>
                <Image onClick={() => this.props.deleteRule()}
                       src={"/images/close-black.png"}/>
            </div>
        );

    }
}

class NavigationBarAttribute extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            rules: []
        }
    }

    handleClick(event) {
        event.preventDefault();
        if (!this.state.isOpen) {
            if (this.state.rules.length === 0) {
                api.get(`/rules?class=${this.props.class.className}&attr=${this.props.attr}`, Auth.createConfig())
                    .then(json => this.setState({rules: json.data.result}))
                    .catch(error => console.log(error));
            }
        }
        this.setState({isOpen: !this.state.isOpen})
    }

    render() {
        const open_icon = <div style={{overflowX: 'hidden'}}>
                            <Image onClick={(event) => this.handleClick(event)}
                                   src={this.state.isOpen ? "/images/minus-box.png" : "/images/plus-box.png"}/>
                            <a style={{color: this.props.current.attrName === this.props.attr ? 'red' : 'blue'}}
                               onClick={event => { event.preventDefault(); if(this.props.attr !== 'IGNORE LIST' ){ this.props.handleAttributes(this.props.attr)}}}
                               href={`/extract-manager`}>{this.props.attr !== 'IGNORE LIST' ? <i>{this.props.attr}</i> : <i>[ {this.props.attr} RULES ]</i>}</a>
                          </div>;

        let rules = this.state.rules ? this.state.rules.map((v, i) => <NavigationBarRule rule={v}
                                                                                         key={i}
                                                                                         deleteRule={() => this.props.deleteRule(v)}
                                                                                         onRuleSelect={(rule) => this.props.onRuleSelect(rule)}/>) : [];

        return (
            <div className="Navigation-attr">
                {open_icon}
                {(this.state.isOpen && this.props.attr !== 'IGNORE LIST')  && <a style={{color: 'blue',marginLeft:24}}
                                                                                 className="Navigation-attr"
                                                                                 onClick={(event) => { event.preventDefault(); this.props.addRule() }} >
                                                                                    Add rule
                                                                              </a>}
                {this.state.isOpen ? rules : null}
            </div>
        );

    }

}

class NavigationBarClass extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            mapped: [],
            attributes: []
        }
    }

    handleClick(event) {
        event.preventDefault();
        if (!this.state.isOpen) {
            if (this.state.attributes.length === 0) {
                api.get(`/attributes?class=${this.props.class.className}`, Auth.createConfig())
                    .then(json => this.setState({attributes: json.data.items}))
                    .catch(error => console.log(error));
            }
            if(this.state.mapped.length === 0) {
                this.get_mapping_rules();
            }
        }
        this.setState({isOpen: !this.state.isOpen})
    }

    get_mapping_rules(){
        api.get(`/mapping/rules/get?class=${this.props.class.className}`, Auth.createConfig())
            .then(json => this.setState({mapped: json.data}))
            .catch(error => console.log(error));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.shouldUpdate !== this.props.shouldUpdate){
            if(this.state.attributes){
                this.setState({attributes: [], mapped: [], isOpen: false})
            }
        }
        if (prevProps.active !== this.props.active && this.props.active){
            document.getElementById(`#${this.props.class.className.replace(/ /g,'_')}`).scrollIntoView(true)
        }
    }

    render() {
        const open_icon = <div id={`#${this.props.class.className.replace(/ /g,'_')}`}>
                            <Image onClick={(event) => this.handleClick(event)}
                                   src={this.state.isOpen ? "/images/minus-box.png" : "/images/plus-box.png"}/>
                            <a style={{color: this.props.active ? 'red' : 'blue'}}
                               onClick={(event) => { event.preventDefault(); this.props.handleClick() }}
                               id={`#${this.props.class.className.replace(/ /g,'_')}`}>
                                {this.props.class.className} ({this.props.class.count})
                            </a>
                          </div>;

        let attributes = this.state.attributes.map((v, i) =>
            <NavigationBarAttribute current={this.props.current}
                                    handleAttributes={attr => this.props.handleAttributes({ attrName: attr, 'class': this.props.class.className })}
                                    attr={v.attributeName}
                                    onRuleSelect={(rule) => this.props.onRuleSelect(rule)}
                                    rules={v.rules}
                                    deleteRule={(rule) => this.props.deleteRule(v,rule)}
                                    key={i}
                                    addRule={() => this.props.addRule(v.attributeName)}
                                    batch={this.props.batch}
                                    class={this.props.class}/>);

        let rules = Array.isArray(this.state.mapped) && this.state.mapped.map((v,i) =>
            <NavigationBarRule rule={v}
                               key={i}
                               deleteRule={() => this.props.deleteRule('', v)}
                               onRuleSelect={(rule) => this.props.onRuleSelect(rule)}/>);

        return (
            <div className="Navigation-class">
                {open_icon}
                {this.state.isOpen ? rules : null}
                {this.state.isOpen  && <a style={{color: 'blue',marginLeft:24}}
                                          className="Navigation-attr"
                                          onClick={(event) => { event.preventDefault(); this.props.addRule('') }} >
                    Add rule
                </a>}
                {this.state.isOpen ? attributes : null}
            </div>
        );

    }

}

class NavigationBarRoot extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            batches: [],
            options: [],
            classes: []
        };

        this.searchBar = React.createRef();
    }

    componentDidMount() {
        this.setHeight()
        this.getBatches();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.item !== prevProps.item || this.props.batch !== prevProps.batch || this.props.shouldUpdate !== prevProps.shouldUpdate) {
            api.get(`/classes?batch=${this.props.batch}`, Auth.createConfig())
                .then(json => this.setState({options: json.data.map(v => {
                    return {
                        value: v.className,
                        label: <>
                            <a href={'#' + v.className.replace(/ /g,'_')}>
                                <React.Fragment>
                                    <span>{v.className}</span>
                                </React.Fragment>
                            </a>
                        </>
                    }
                    }), classes: json.data,  shouldUpdate:!this.state.shouldUpdate}))
                .catch(error => console.log(error));

        }
        if(this.props.windowHeight !== prevProps.windowHeight){
            this.setHeight()
        }

    }

    handleClassSelect(className) {
        this.setState({className: className});
        if (className) {
            this.props.handleClassChange(className)
        }
    }

    handleBatchSelect(batch) {
        if (batch) {
            this.props.handleBatchChange(batch)
        }
    }

    setHeight(){
        this.setState({height: this.searchBar.current.offsetHeight, maxHeight: this.props.windowHeight - this.searchBar.current.offsetHeight - 100})
    }

    getBatchesOptions = data => {
        return data.map(v=> {
            return {
                value: v,
                label: v
            }
        })
    }

    getBatches() {
        api.get(`/batches`)
            .then(json => {
                this.setState({batches: this.getBatchesOptions(json.data.items)});
                let batch = new URLSearchParams(window.location.search.replace('%', '&')).get('batch');
                if (!batch) {
                    batch = json.data.items[json.data.items.length - 1]
                }
                this.props.handleBatchChange(batch, json.data.results, json.data.size, true)

            })
            .catch(error => alert(error))
    }

    render() {

        const open_icon = <div style={{'marginTop': 10}}>
            <Image src={"/images/minus-box.png"}/>
            <span onClick={() => this.props.handleClassChange('')}>Class Library</span>
        </div>;

        let classes = this.state.classes.map((v, i) => <NavigationBarClass current={this.props.attr}
                                                                           handleAttributes={(attr) => this.props.handleAttributes(attr)}
                                                                           active={v.className === this.props.item}
                                                                           addRule={(attr) => this.props.addRule(v, attr)}
                                                                           shouldUpdate={this.props.shouldUpdate}
                                                                           deleteRule={(attr,rule) => this.props.deleteRule(v,attr,rule)}
                                                                           onRuleSelect={(rule) => this.props.onRuleSelect(rule)}
                                                                           handleClick={() => this.props.handleClassChange(v.className)}
                                                                           batch={this.props.batch} key={i}
                                                                           class={v}/>);

        return (
            <div>
            <div className='Navigation-Bar' ref={this.searchBar} style={{maxHeight: this.state.height}}>
                <AutoComplete
                    id="combo-box-batch"
                    options={this.state.batches}
                    style={{margin: '5px 0', width: '100%'}}
                    placeholder={'Search for batches'}
                    onSelect={(values) => this.handleBatchSelect(values)}
                    filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }                />
                <AutoComplete
                    id="combo-box-demo"
                    placeholder={'Search for classes'}
                    options={this.state.options}
                    style={{margin: '5px 0',  width: '100%'}}
                    onSelect={(values) => this.handleClassSelect(values)}
                    filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                />
            </div>
                <div className='library' style={{marginTop: this.state.height ? this.state.height + 5 : 0}}>
                {open_icon}
                {this.props.batch && classes}
                </div>
            </div>
        );

    }

}

export default NavigationBarRoot;
