import React, {useEffect, useState} from 'react';
import {ColoredColumns, ManualPagedTable} from "./Class Library Component";
import axios from "axios";
import {properties} from "../properties";
import api from "../api";
import './helpers.css'
import {message, Table, Switch} from "antd";
import {ContextMenu} from "./ContextMenu";
import {DataCard} from "./components/DataCard";
import {AsyncDataSource} from "../Register/Register";
// import {Switch} from "react-router";

class AttributesDataSource extends AsyncDataSource {

    constructor(_class) {
        super();
        this.selectedClass = _class
    }

    async values(page, limit, query) {
        return axios.get(
            properties.clLink + `/classes/${this.selectedClass.id}/attributes`,
            api._withHeaders({params: {page: page, limit: limit, q: query}})
        ).then(
            response => {
                return response.data.map(el => ({key: el.id, ...el}));
            }
        );
    }
}

function MatrixTable({options, dataSource, setDataSource, loading}) {

    let columns = [
        {
            key: 'name',
            dataIndex: 'name',
            className: 'text-center',
            title: 'Attribute'
        }, {
            key: 'isMultivalued',
            dataIndex: 'isMultivalued',
            className: 'text-center',
            title: 'Is Multivalued',
            render: (isMultivalued, item) => <Switch onChange={event => handleSwitchClick(item)} checkedChildren="Yes" checked={isMultivalued} unCheckedChildren="No" defaultChecked={false} />

        }
    ]

    const handleSwitchClick = (attribute) => {
        let data = [...dataSource];
        const item = data.find(x => x.name === attribute.name)
        if (item) {
            item['isMultivalued'] = true
            item['edit'] = true
        }
        setDataSource(data)
    }


    const handleClick = (option, i, attribute) => {
        let data = [...dataSource];
        const item = data.find(x => x.name === attribute.name)
        const ranking = [0,1,2,3,4]
        if (item) {
            let _list = []
            for (let x in ranking) {
                _list.push(item[x])
            }
            _list = _list.filter(x => x !== option)
            _list.splice(i, 0, option)
            for (let x in ranking) {
                item[x] = _list[x]
            }
            item['edit'] = true
        }
        setDataSource(data)
    }

    const beatify = (value) => {
        if (value) {
            return value
        }
        return '(blank)'
    }

    const styles = {
        'P&ID': {
            color: "red",
            background: "#ffdcdb33",
        },
        'Datasheet': {
            color: "orange",
            background: "#ffeedb33",
        },'Equipment list': {
            color: "green",
            background: "#dbffdb33",
        },'Line List': {
            color: "purple",
            background: "#f3defa33",

        }, 'Supplier Datasheet': {
            color: "blue",
            background: "#dbe4ff33",
        }, 'Lists': {
            color: "purple",
            background: "#f3defa33",
        }, 'P&ID Master': {
            color: "red",
            background: "#ffdcdb33",
        }
    }

    const docs = options.map((doc, i) => {
            return {
                key: i,
                title: i + 1,
                dataIndex: i,
                className: 'text-center',
                render: (value, attribute) => <ContextMenu index={i} attr={attribute.name} options={options} clickHandler={option => handleClick(option, i, attribute)}><div style={styles[value]} className={attribute[i] || 'empty' }>{beatify(value)}</div></ContextMenu>
            }
        } )

    columns = columns.concat(docs)

    const coloredColumns = new ColoredColumns(
        columns,
        record => record?.edit ? '#feffdb66' : ''
    ).values();


    return (
        <Table
            loading={loading}
            columns={coloredColumns}
            dataSource={dataSource.sort((a, b) => a.order - b.order)}
            pagination={false}
            rowKey={r => r.id}
            size="small"
        />
    )
}

export function SourceMatrix ({selectedClass}) {

    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [options, setOptions] = useState([])


    const getSaved = () => {
        api.get(`/source-matrix/get?class_id=${selectedClass.name}&type=${selectedClass.type}`)
            .then(json => {
                mapp(json.data)
            })
    }

    const getOptions = () => {
        api.get(`/source-matrix/options`)
            .then(json => {
                setOptions(json.data)
            })
    }

    const findKey = (obj, value) => {
        for (let key in obj) {
            if (key !== '' && obj[key] === value) {
                return key
            }
        }
        return ''
    }

    const mapp = saved => {
        const data = [...dataSource]
        for (let i = 0; i < data.length; i++) {
            let saved_attr = saved.find(a => a.attr_id === data[i].name)
            let attr = data[i]
            if (saved_attr) {
                for (let x in saved_attr) {
                    attr[x] = saved_attr[x]

                }
                attr['edit'] = false
            } else {
                for (let x in options) {
                    attr[x] = options[x]
                }
                attr['edit'] = false
            }
        }
        setDataSource(data)
    }

    useEffect(() => {
        if (!loading) {
            getSaved()
        }
    }, [loading])

    useEffect(() => {
        setLoading(true)
        api.get(
            `/class-library/classes/${selectedClass.id}/attributes`,
            api._withHeaders()
        ).then(
            response => {
                setDataSource(response.data.map(el => ({key: el.id, ...el})))
            }
        ). finally(() => setLoading(false));
        getOptions()

    }, [selectedClass])

    const save = () => {
        const attributes = dataSource.map(attribute => {
            const mapping = {}
            for (let x=0; x < options.length; x ++) {
                mapping[x] = attribute[x] || ''
            }
            return {
                class_id: selectedClass.name,
                class_type: selectedClass.type,
                attr_id: attribute.name,
                isMultivalued:attribute.isMultivalued,
                ...mapping
        }
        })
        api.post(`/source-matrix/save`, attributes).then(() => {
            message.success('Attributes were saved!')
            getSaved()
        })
            .catch(() => message.error('Something went wrong during saving!'))
    }

    return (
        <DataCard title={<div>Source Matrix for {selectedClass.name} class {selectedClass && <div className='link' style={{float: "right"}} onClick={() => save()}>Save all</div>}</div>} style={{paddingTop: 65}}>
            {selectedClass ? <MatrixTable dataSource={dataSource} setDataSource={setDataSource} loading={loading} options={options}/> : <div>Select class to work with</div>}
        </DataCard>
    )

}
